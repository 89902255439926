import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopinfoModel, DepartmentModel } from '../model/shopinfo.model';
import { ShopinfoService } from '../shopinfo.service';
import { AreaService } from '../../area/area.service';
import { DepartmentService } from '../../department/department.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
@Component({
  selector: 'app-shop-edit',
  templateUrl: './shop-edit.component.html',
  styleUrls: ['./shop-edit.component.scss']
})
export class ShopEditComponent implements DirtyCheck {
  title: string = "New Shop";
  editForm: FormGroup;
  shopInfo: ShopinfoModel = { departments: [] };
  isFormDirty: boolean = false;
  shoptype: any;
  areaList: any;
  departmentList: DepartmentModel[] = [];
  displayedColumns: string[] = ['code', 'name'];
  sushishop_list: any;
  mainshop_list: any;
  bento_shop_list: any;
  selected_shop_type: any;
  is_display_bento_shop: boolean = false;
  is_display_dt_shops: boolean = false;
  isDisabled: boolean = false;
  selectedBentoShop: any;
  selectedSushiShop: any;
  mainShop: any;
  shopList: any;
  id:any;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private shopService: ShopinfoService,
    private AreaService: AreaService,
    private ShopInfoService: ShopinfoService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {
    // this.loadData();
    let shopId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.shopInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.shopInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.shopInfo.description),
      shop_type: this.builder.control(this.shopInfo.shop_type),
      area_id: this.builder.control(this.shopInfo.area_id),
      address: this.builder.control(this.shopInfo.address, [Validators.required]),
      city: this.builder.control(this.shopInfo.city, []),
      state: this.builder.control(this.shopInfo.state, []),
      country: this.builder.control(this.shopInfo.country, []),
      is_excluded_summary: this.builder.control(this.shopInfo.is_excluded_summary, []),
      zip_code: this.builder.control(this.shopInfo.zip_code, []),
      // departments: this.builder.array([]),
      departments: this.builder.control(this.shopInfo.departments, []),
      main_shop_id: this.builder.control(''),
      dt_sushi_shop_id: this.builder.control(''),
      dt_bento_shop_id: this.builder.control(''),



    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });
    // if (shopId !== null && parseInt(shopId) !== 0) {
    //   this.setShopInfo(shopId);
    // }
  }

  ngOnInit(): void {
    this.loadMasterData();
    console.log(this.shopInfo)
  }
  

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/area']);


  }
  loadMasterData(): void {
    // let requestData = {search:{filters:{scope:{limit:100000,offset:0}},sort:[]}}
    let requestData = {
      "search": {
          "filters": {
              "scope": {
                  "limit": 10,
                  "offset": 0
              }
          },
          "sort": [
              {
                  "column": "name",
                  "order": "asc"
              }
          ]
      }
  };
    this.ShopInfoService.getShopList(requestData).subscribe(
      (response: any) => {
        if (response.status == 'SUCCESS') {
          this.departmentList = response.data.shops;
          this.setShopInfo(this.departmentList[0].id);
          console.log(this.departmentList[0].id);
          const newDept = this.departmentList.filter((dept: DepartmentModel) => !this.shopInfo.departments.some(sd => sd.id === dept.id));

          this.shopInfo.departments.push(...this.departmentList);
        }
      });
      console.log(this.id);
    this.AreaService.getAreaList(requestData).subscribe(
      (response: any) => {
        if (response.status == 'SUCCESS') {
          this.areaList = response.data.areas;
        }
      });
    // this.shopService.getShopTypeList().subscribe(
    //   (response: any) => {
    //     if (response.status == 'SUCCESS') {
    //       this.shoptype = response.data.shop_types;
    //     }
    //   });
    // this.shopService.getShopList(requestData).subscribe(
    //   (response: any) => {
    //     if (response.status == 'SUCCESS') {
    //       this.shopList = response.data.shops;
    //       this.mainshop_list = this.shopList.filter((m: any) => m.shop_type === 'SUSHI');
    //       this.sushishop_list = this.shopList.filter((s: any) => s.shop_type === 'SUSHI');
    //       this.bento_shop_list = this.shopList.filter((b: any) => b.shop_type === 'BENTO');
    //     }
    //   });

     
      



  }

  /**
   * If tas is is null set an empty shop info
   * if shop id is not null call api to rtrive the info
   * @param shopId 
   */
  setShopInfo(shopId: any): void {

    let response = this.shopService.getShopById(shopId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          console.log(response.data.area);
          this.shopInfo = response.data.area;
          //this.departmentList.push(...response.data.department_list);

          // const newDept = this.departmentList.filter((dept: DepartmentModel) => !this.shopInfo.departments.some(sd => sd.id === dept.id));

          // this.shopInfo.departments.push(...newDept);

          // for (const dept of this.departmentList) {
          //   dept.is_exist = this.shopInfo.departments.some(sd => sd.id === dept.id);
          // }
          console.log(this.shopInfo);
          // this.shoptype = response.data.shop_types;
          // this.areaList = response.data.area_list;
          // this.mainshop_list = response.data.main_shop;
          // this.sushishop_list = response.data.sushi_shop;
          // this.bento_shop_list = response.data.bento_shop;
          // this.selected_shop_type = response.data.shops[0].shop_type;
          // this.mainShop = response.data.main_shop_id;
          // this.selectedSushiShop = response.data.selected_sushi_shop_id;
          // this.selectedBentoShop = response.data.selected_bento_shop_id;

          if (this.selected_shop_type === 'SUSHI') {
            this.is_display_bento_shop = false;
            this.is_display_dt_shops = false;
            this.isDisabled = true;
          } else if (this.selected_shop_type === 'OO') {
            this.is_display_bento_shop = false;
            this.is_display_dt_shops = false;
            this.isDisabled = false;
          } else if (this.selected_shop_type === 'BENTO') {
            this.is_display_bento_shop = true;
            this.is_display_dt_shops = false;
            this.isDisabled = true;
          } else if (this.selected_shop_type === 'DT') {
            this.is_display_bento_shop = false;
            this.is_display_dt_shops = true;
            this.isDisabled = false;
          }
          this.editForm.patchValue(this.shopInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.shopInfo.id == 0) ? "New Shop" : "Editing : " + this.shopInfo.name;
    return title;
  }
  // Access the department form array
  get departmentControls() {
    console.log(this.editForm.get('departments'));
    return this.editForm.get('departments') as FormArray;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {
    console.log(this.editForm.valid);

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.shopInfo, this.editForm.value);
      console.log(this.shopInfo);
      this.shopService.update(this.shopInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.shopInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.router.navigate(['/area']);

          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    } else {
      this.editForm.markAllAsTouched();
    }
  }

  onShopTypeChange() {
    let shopType = this.selected_shop_type;
    if (this.selected_shop_type === 'SUSHI') {
      this.is_display_bento_shop = false;
      this.is_display_dt_shops = false;
      this.isDisabled = true;
    } else if (this.selected_shop_type === 'OO') {
      this.is_display_bento_shop = false;
      this.is_display_dt_shops = false;
      this.isDisabled = false;
    } else if (this.selected_shop_type === 'BENTO') {
      this.is_display_bento_shop = true;
      this.is_display_dt_shops = false;
      this.isDisabled = true;
    } else if (this.selected_shop_type === 'DT') {
      this.is_display_bento_shop = false;
      this.is_display_dt_shops = true;
      this.isDisabled = false;
    }

  }

}
