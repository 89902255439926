import { Injectable } from '@angular/core';
import { RequestData } from '../../../../common/models/request-data.model';
import { StocksModel } from './models/stocks.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class StocksService {

  constructor(private http: HttpClient) { }

  
  getStocksList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/stocks/get', requestData);

  }

  getStockById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/stocks/get/'+id, '');

  }
  getList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/category/getCategory', requestData);

  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/hq/sale-item/export-items', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

   /**
   * 
   * @param areaInfo 
   * Calls the update API to insert/update the record
   */
   update(stockInfo: StocksModel): Observable<any> { 
    if(stockInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/hq/stocks/update', stockInfo);
    } else{
      return this.http.post(environment.baseUrl+'/hq/stocks/add', stockInfo);
    }
  }


    /**
   * 
   * @param areaInfo 
   * Calls the API to delete the record
   */
    delete(stockInfo: StocksModel): Observable<any>  {
      return this.http.post(environment.baseUrl+'/hq/stocks/delete/'+stockInfo.id, stockInfo);
    }
}





