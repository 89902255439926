import { Injectable } from '@angular/core';
import { RequestData } from 'src/app/common/models/request-data.model';
import { UomModel } from './model/uom.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UomService {

  constructor(private http: HttpClient) { }

  getBaseUomList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/uom/getBase', requestData);

  }
  
  getUomList(requestData:RequestData): Observable<any> {
    console.log(requestData)
    return this.http.post(environment.baseUrl+'/uom/get', requestData);
    

  }

  getUomById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/uom/get/'+id, '');

  }

   /**
   * 
   * @param areaInfo 
   * Calls the update API to insert/update the record
   */
   update(uomInfo: UomModel): Observable<any> { 
    if(uomInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/uom/update', uomInfo);
    } else{
      return this.http.post(environment.baseUrl+'/uom/add', uomInfo);
    }
  }


    /**
   * 
   * @param areaInfo 
   * Calls the API to delete the record
   */
    delete(uomInfo: UomModel): Observable<any>  {
      return this.http.post(environment.baseUrl+'/uom/delete/'+uomInfo.id, uomInfo);
    }

    downloadExcel(requestData: any): Observable<Blob> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL
  
      return this.http.post(environment.baseUrl + '/hq/uom/export-uom-list', requestData, { headers, responseType: 'blob' })
        .pipe(
          //catchError(this.handleError)
        );
  
    }
}





