<div class="edit-page-root-container uom-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details">
            <form class="edit-form" [formGroup]="editForm">
                <div class="detail-section">
                    <div class="content-container">
                        <div class="input-group">
                            <div class="input-box-left"> <mat-form-field class="code">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="code" maxlength="50"
                                        [readonly]="uomInfo.id!==undefined && uomInfo.id!==0">
                                    <mat-error *ngIf="editForm.controls['code'].hasError('required')">Uom code should be
                                        entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                        10.</mat-error>
                                </mat-form-field></div>

                            <div class="input-box-right"> <mat-form-field class="name">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name">
                                    <mat-error *ngIf="editForm.controls['name'].hasError('required')">Uom name should be
                                        entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is
                                        50.</mat-error>
                                </mat-form-field></div>

                        </div>

                        <mat-form-field class="description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                            <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is
                                200.</mat-error>
                        </mat-form-field>
                        <div class="input-group">
                            <div>
                                <mat-form-field>
                                    <mat-label>Decimal Places</mat-label>
                                    <mat-select formControlName="decimal_places">
                                        <mat-option *ngFor="let value of numbers" [value]="value">
                                            {{ value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="symbol">
                                    <mat-label>Symbol</mat-label>
                                    <input matInput formControlName="uom_symbol" maxlength="50">
                                </mat-form-field>
                            </div>


                        </div>
                        <!-- <div class="input-group">
                            <div>
                                <mat-form-field class="compound_unit">
                                    <mat-label>Compound Unit</mat-label>
                                    <input matInput formControlName="compound_unit" maxlength="50">
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field>
                                    <mat-label>Base Uom</mat-label>
                                    <mat-select formControlName="base_uom_id">
                                        <mat-option *ngFor="let uoms of base_uom_id" [value]="uoms.id">
                                            {{ uoms.code }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div> -->
                    </div>
                </div>







            </form>
        </div>
    </div>
</div>