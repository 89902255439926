<app-system-menu> </app-system-menu>

<div class="department-component-container page-root-container">
        <div class="page-header">
                <div class="page-title">

                        <img class="icon" src="assets/images/menu/svg/department.svg"><span
                                class="title">Departments</span>
                </div>
                <div class="page-actions">
                        <button mat-button color="primary" class="btn-add"
                                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        <!-- <button mat-button color="accent" class="btn-export" (click)="exportClicked()">
                                <mat-icon>save_alt</mat-icon>Export</button> -->
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>
                <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]"
                [filter]="filter" (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)"
                [hidePageSize]="true">
        </app-search-filter>
               <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="dataSource">
                                <ng-container matColumnDef="item">
                                        <th mat-header-cell *matHeaderCellDef (click)="sortData($event)"
                                                class="item-header">#</th>
                                        <td mat-cell *matCellDef="let department; let i = index" class="item-column">{{
                                                (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                                </ng-container>
                                <ng-container matColumnDef="code">
                                        <th mat-sort-header="code" mat-header-cell style="width: 80px;"
                                                *matHeaderCellDef (click)="sortData($event)">Code</th>
                                        <td mat-cell *matCellDef="let department" style="width: 80px;">
                                                {{department.code}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                        <th mat-sort-header="name" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)"> Name </th>
                                        <td mat-cell *matCellDef="let department"> {{department.name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                        <th mat-sort-header="description" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)"> Description </th>
                                        <td mat-cell *matCellDef="let department"> {{department.description}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>

                                                <mat-menu #rowMenu>
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                </mat-menu>
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row"
                                        *matRowDef="let department; columns: displayedColumns;"
                                        (click)="onRowSelected(department)" (dblclick)="onEdit(department)"
                                        [ngClass]=" {'row-selected' : department.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>

                <!-- </ng-container> -->
                <!-- <ng-template #nodata> -->
                @if(pagingData.length<=0){ <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
        </div>
        <!-- </ng-template> -->
        }
        </div>

</div>
<!-- <app-department-summary [department]="selectedItem" (onEdit)="onEdit($event)"
        (onDelete)="onDelete($event)"></app-department-summary> -->