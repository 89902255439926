
<div class="user-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <mat-list-item class="mat-list-item" routerLink="/employee-category" [routerLinkActive]="['is-active']" (click)="resetState()"  [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/user-group.svg">
                        <span class="master-title" style="word-wrap: break-word;">User Group
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/employee" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Employee' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" >
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/user.svg">
                        <span class="master-title">Users
                        </span>
                    </div>
                </mat-list-item>
                <!-- <mat-list-item class="mat-list-item" routerLink="/payroll" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Payroll Group' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/custom-menu.svg">
                        <span class="master-title"> Custom Menu
                        </span>
                    </div>
                </mat-list-item> -->
                <!-- <mat-list-item class="mat-list-item" routerLink="/shop" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Shop Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/custom-menu-item.svg">
                        <span class="master-title">Custom Menu Item
                        </span>
                    </div>
                </mat-list-item> -->
                <!-- <mat-list-item class="mat-list-item" routerLink="/staff" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Staff Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/area-manager.svg">
                        <span class="master-title">Area Manager

                        </span>
                    </div>
                </mat-list-item> -->
             
         
         
            </div>
        </div>
    </div>
</div>
