import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppConstants } from '../../../../../common/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { SaleItemComponent } from '../../sale-item.component';
import { SaleItemModel } from '../../model/sale-item.model';

@Component({
  selector: 'app-sale-item-summary',
  templateUrl: './sale-item-summary.component.html',
  styleUrl: './sale-item-summary.component.scss'
})
export class SaleItemSummaryComponent {

 @Input() item: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  items: any;

  constructor(
    private dialog: MatDialog
  ) {
    let sessionItem = sessionStorage.getItem(AppConstants.SESSION_VAR_ITEM);
    this.items = (sessionItem !== null) ? JSON.parse(sessionItem) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  /**
   * Closed the summary panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  onEditAction() {
    this.onEdit.emit(this.item);
  }
  /**
   * Delete the current item.
   */
  onDeleteAction() {
    this.onDelete.emit(this.item);
  }

}
