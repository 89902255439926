import { Injectable } from '@angular/core';
import { RequestData } from '../../../common/models/request-data.model';
import { ItemCategoryModel } from './model/item-category.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ItemCategoryService {


  constructor(private http: HttpClient) { }

  
  getList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/itemclass/get', requestData);

  }
 
  getParentCategory(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/itemclass/getSubClass', requestData);

  }

  getById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/itemclass/get/'+id, '');

  }

   /**
   * 
   * @param areaInfo 
   * Calls the update API to insert/update the record
   */
   update(itemCategoryInfo: ItemCategoryModel): Observable<any> { 
    if(itemCategoryInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/itemclass/update', itemCategoryInfo);
    } else{
      return this.http.post(environment.baseUrl+'/itemclass/add', itemCategoryInfo);
    }
  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/hq/itemcategory/export-item-category', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

    /**
   * 
   * @param areaInfo 
   * Calls the API to delete the record
   */
    delete(itemCategoryInfo: ItemCategoryModel): Observable<any>  {
      return this.http.post(environment.baseUrl+'/itemclass/delete/'+itemCategoryInfo.id, itemCategoryInfo);
    }
}





