<div class="employee-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <mat-list-item class="mat-list-item" routerLink="/employee-category" [routerLinkActive]="['is-active']" (click)="resetState()"  [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/employee-category.svg">
                        <span class="master-title" style="word-wrap: break-word;">Category
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/employee" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Employee' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" >
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/employees.svg">
                        <span class="master-title">Employee</span>
                    </div>
                </mat-list-item>
                <!-- <mat-list-item class="mat-list-item" routerLink="/payroll" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Payroll Group' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/payroll-group.svg">
                        <span class="master-title"> Payroll Group 
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/shop" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Shop Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/shop-payroll.svg">
                        <span class="master-title">Shop Payroll</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/staff" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Staff Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/staff-payroll.svg">
                        <span class="master-title">Staff Payroll
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/attendence-export.svg">
                        <span class="master-title">Attendance Export
                        </span>
                    </div>
                </mat-list-item> -->
         
           
         
         
            </div>
        </div>
    </div>
</div>
