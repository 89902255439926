<mat-sidenav-container class="sidnav-container">

    <mat-sidenav class="sidnav" #sidenav [mode]="mode" opened>
        <mat-list class="nav-menu-list" [style]="'min-width:50px'">
            <mat-list-item class="mat-list-item" routerLink="home" [routerLinkActive]="['is-active']"
                (click)="closeSidenav()">
                <div class="mat-list-item-contents">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" baseProfile="tiny" version="1.2"
                        viewBox="0 0 24 24" id="menu">
                        <path
                            d="M8 3H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 3zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 3zM8 13H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 13zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 13z">
                        </path>
                    </svg>
                    <span> Dashboard</span>
                </div>
            </mat-list-item>
            <mat-list-item class="mat-list-item" routerLink="area" [routerLinkActive]="['is-active']">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/system.svg" style="padding-top: 8px; height: 27px;">
                    <span>System</span>
                </div>
            </mat-list-item>
            <mat-list-item class="mat-list-item" routerLink="uom" [routerLinkActive]="['is-active']">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/product.svg" style="padding-top: 8px; height: 27px;">

                    <span>Products</span>
                </div>
            </mat-list-item>
            <mat-list-item class="mat-list-item" routerLink="vouchers" [routerLinkActive]="['is-active']">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/promotions.svg" style="height: 27px; padding-top: 8px;">

                    <span>Promotions</span>
                </div>
            </mat-list-item>

            <mat-list-item class="mat-list-item" routerLink="user" [routerLinkActive]="['is-active']">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/user.svg" style="height: 27px; padding-top: 8px;">

                    <span>Users</span>
                </div>
            </mat-list-item>
            <mat-list-item class="mat-list-item" routerLink="employee-category" [routerLinkActive]="['is-active']">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/employees.svg" style="height: 27px; padding-top: 8px;">

                    <span>Employees</span>
                </div>
            </mat-list-item>
            <mat-list-item class="mat-list-item" routerLink="report" [routerLinkActive]="['is-active']">
                <div class="mat-list-item-contents">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="volume-analysis" style="width:27px">
                        <path
                            d="M7,25.08H5V13a3,3,0,0,1,3-3H23v2H8a1,1,0,0,0-1,1ZM45,38H43V29H41v9H7V35H21V33H3v2H5v3H3a1,1,0,0,0-1,1v2a5,5,0,0,0,5,5H41a5,5,0,0,0,5-5V39A1,1,0,0,0,45,38ZM24,10H46V25a3,3,0,0,1-3,3H27a3,3,0,0,1-3-3Zm5,5H41V13H29Zm0,4H41V17H29Zm0,4H41V21H29ZM46,5V8H24V5a3,3,0,0,1,3-3H43A3,3,0,0,1,46,5ZM31,4H28V6h3Zm5,0H33V6h3ZM11,27a1,1,0,0,0-1-1H6a1,1,0,0,0-1,1v5h6Zm3-6a1,1,0,0,0-1,1V32h6V22a1,1,0,0,0-1-1Z"
                            data-name="33 Volume Analysis, Bar Chart, Bar Graph, Column Graph, Graphical Representation">
                        </path>
                    </svg>
                    <span>Reports</span>
                </div>
            </mat-list-item>
            <!-- <mat-list-item class="mat-list-item" routerLink="util" [routerLinkActive]="['is-active']">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/utils.svg" style="height: 27px; padding-top: 8px;">

                    <span>Utils</span>
                </div>
            </mat-list-item> -->
        </mat-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidnav-content-container">
        <div class="contents">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>
<!-- <app-reward-submenu [show]="showRewardMenu"></app-reward-submenu> -->