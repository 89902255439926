import { Injectable } from '@angular/core';
import { AreaModel } from './model/area.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(private http: HttpClient) { }

  getAreaList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/area/get', requestData);

  }

  getAreaById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/area/get/'+id, '');

  }

   /**
   * 
   * @param areaInfo 
   * Calls the update API to insert/update the record
   */
   update(areaInfo: AreaModel): Observable<any> { 
    if(areaInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/area/update', areaInfo);
    } else{
      return this.http.post(environment.baseUrl+'/area/add', areaInfo);
    }
  }


    /**
   * 
   * @param areaInfo 
   * Calls the API to delete the record
   */
    delete(areaInfo: AreaModel): Observable<any>  {
      return this.http.post(environment.baseUrl+'/area/delete/'+areaInfo.id, areaInfo);
    }

    downloadExcel(requestData: any): Observable<Blob> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL
  
      return this.http.post(environment.baseUrl + '/hq/area/export-region-list', requestData, { headers, responseType: 'blob' })
        .pipe(
          //catchError(this.handleError)
        );
  
    }

}
