import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppConstants } from '../../../../../common/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ItemCategoryComponent } from '../../item-category.component';
import { ItemCategoryModel } from '../../model/item-category.model';
@Component({
  selector: 'app-item-category-summary',
  templateUrl: './item-category-summary.component.html',
  styleUrls: ['./item-category-summary.component.scss']
})
export class ItemCategorySummaryComponent {

 @Input() category: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  categories: any;

  constructor(
    private dialog: MatDialog
  ) {
    let sessionCategory = sessionStorage.getItem(AppConstants.SESSION_VAR_ITEMCATEGORY);
    this.categories = (sessionCategory !== null) ? JSON.parse(sessionCategory) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['category']) {
    //   this.show = (this.category !== undefined);

    // }
  }

  /**
   * Closed the summary panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  onEditAction() {
    this.onEdit.emit(this.category);
  }
  /**
   * Delete the current item.
   */
  onDeleteAction() {
    this.onDelete.emit(this.category);
  }

}
