import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ItemCategoryService } from './item-category.service';
import { SnackBarService } from '../../../common/components/snackbar/snackbar.service';
import { SnackBarData } from '../../../common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from '../../../common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import { FloatingMenuOption } from '../../../common/components/master-floating-menu/model/master-floating-model';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from '../../../common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { ItemCategoryModel } from './model/item-category.model';
import { MatDialog } from '@angular/material/dialog';
import { StateManagerService } from '../../../common/services/state.manager.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { EmployeeCategoryModel } from '../../employees/employee-category/employee-category/model/employee-category.model';

@Component({
  selector: 'app-item-category',
  templateUrl: './item-category.component.html',
  styleUrls: ['./item-category.component.scss']
})
export class ItemCategoryComponent {

  itemCategoryList: ItemCategoryModel[] = [];
  showSearch: boolean = false;
  isLoading = true;
  IsDownloading = false;

  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "item_classes.code",
    },
    {
      title: "Name",
      column: "item_classes.name",
    },
    {
      title: "Department",
      column: "item_classes.department_id",
      type: "list",
      values: []
    },
    {
      title: "Super Class",
      column: "item_classes.super_class_id",
      type: "list",
      values: []
    },
  ];
  displayedColumns = ['code', 'name', 'department_id', 'super_class_id', 'action'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };
  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: ItemCategoryModel;
  showSummaryPanel: boolean = false;
  selection = new SelectionModel<ItemCategoryModel>(true, []);
  dataSource = new MatTableDataSource(this.itemCategoryList);

  constructor(private breakpointObserver: BreakpointObserver,
    private ItemCategoryService: ItemCategoryService,

    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router, private stateManagerService: StateManagerService) {

    this.itemCategoryList = [];
  }
  /**
    * initilaize the device model
    */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
    // this.loadUomList();
    // this.loadData();
  }
  /** 
  * Set the datasource
  */
  setDataSource(employeeList: EmployeeCategoryModel[]) {
    this.dataSource = new MatTableDataSource(employeeList);
    this.dataSource.sort = this.sort;
  }
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  ngAfterViewInit(): void {

    this.loadPreviousState();
    // this.loadData();
  }

  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column, previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter, true);

    } else {
      this.setSortColumn('item_classes.name', 'asc');
      this.loadData();
    }

  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction });
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;


  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.itemCategoryList.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.itemCategoryList);
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }

  /**
     * Builds the request data to be send to API
     * @returns Request Data
     */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  /**
    * Set the column filter and reaload the daaa
    * @param columFilter 
    */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

  /**
    * On delete menu item selected
    * Confirm the action and call the api to update
    */
  onDelete(ItemCategoryInfo: ItemCategoryModel): void {
    const stockCategoryId = ItemCategoryInfo.id;
    const message = `Are you sure, you want to delete ` + (ItemCategoryInfo.name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.ItemCategoryService.delete(ItemCategoryInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + ItemCategoryInfo.name + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   * loads the data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    let response = this.ItemCategoryService.getList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        // this.itemCategoryList = response.data.category;
        this.filter[2].values = (response.data.item_classes.map((dpt: any) => {
          return {
            title: dpt.department_name
            , value: dpt.department_id
          };
        }));

        this.filter[3].values = (response.data.item_classes.map((sup: any) => {
          return {
            title: sup.super_class_name
            , value: sup.super_class_id
          };
        }));

        this.setDataSource(response.data.item_classes);

        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.selectedItem = undefined;
        } else {
          this.onRowSelected(this.itemCategoryList[0]);
          this.stateManagerService.setState(this.constructor.name, requestData);

        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }


  /**
  * On areainfo selected
  * @param areaInfo 
  */
  onRowSelected(ItemCategoryInfo: ItemCategoryModel): void {
    this.selectedItem = ItemCategoryInfo;
    this.showSummaryPanel = true;
  }

  /**
    *  Set the sort param and relead the data
    * @param $event sort data
    * {"active": "email","direction": "asc"}
    *   
    */
  sortData($event: any): void {
    var sd = this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }
  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }

  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(ItemCategoryInfo: ItemCategoryModel): void {

    this.router.navigate(['item-category-edit', ItemCategoryInfo.id]);
  }


  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }

  /**
   * On Add clicked
   * Show the dialog to enter new area
   */
  addClicked(): void {

    this.router.navigate(['item-category-edit', 0]);

  }
  exportClicked() {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    this.IsDownloading = true;
    this.ItemCategoryService.downloadExcel(requestData)
      .subscribe({
        next: blob => {
          // Download logic
          this.IsDownloading = false;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'employeecategory.csv'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;


        },
        error: error => {
          // Handle errors
          this.IsDownloading = false;
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.isLoading = false;
          // Display user-friendly error message to the user
        }
      }
      );
  }

}










