
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { UomModel } from './model/uom.model';
import { UomService } from './uom.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StateManagerService } from 'src/app/common/services/state.manager.service';

@Component({
  selector: 'app-uom',
  templateUrl: './uom.component.html',
  styleUrls: ['./uom.component.scss']
})
export class UomComponent {
  uomList: UomModel[]=[];
  showSearch: boolean = false;
  isLoading = true;
  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "uoms.code",
    },
    {
      title: "Name",
      column: "uoms.name",
    },
    {
      title: "Description",
      column: "uoms.description",
    },
    {
      title: "Symbol",
      column: "uoms.uom_symbol",
    },
    {
      title: "Decimal Plces",
      column: "uoms.decimal_places",
    },
    // {
    //   title: "Compound",
    //   column: "uoms.compound_unit",
    // },
    // {
    //   title: "Base UOM",
    //   column: "uoms.base_uom_id",
    // },
    // {
    //   title: "Base UOM Units",
    //   column: "uoms.compound_unit",
    // },
  ];
  displayedColumns = ['code', 'name','description','uom_symbol','decimal_places','action'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };
  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: UomModel;
  showSummaryPanel: boolean = false;
  selection = new SelectionModel<UomModel>(true, []);
  IsDownloading: boolean=false;
  
  
  dataSource = new MatTableDataSource(this.uomList);
 constructor(private breakpointObserver: BreakpointObserver,
  private UomService: UomService,
  public dialog: MatDialog,
  private snackBarService: SnackBarService,private stateManagerService: StateManagerService,
  private router: Router) {

  this.uomList = [];
}
 /**
   * initilaize the device model
   */
 ngOnInit(): void {
  this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
    if (state.matches) {
      this.isHandset = true;
    } else {
      this.isHandset = false;
    }
  });
  //this.loadRating();
  this.loadData();
}

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }

  
/**
   * Builds the request data to be send to API
   * @returns Request Data
   */
buildRequestData(): RequestData {
  let searchParam = this.getSearchParam();
  let requestData: RequestData = {
    search: {
      filters: {
        simpleSearch: searchParam.freeSearch,
        advSearch: searchParam.advSearch,
        scope: {
          limit: this.pagingData.pageSize,
          offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
        }
      },
      sort: this.getSort(),
    }
  };

  return requestData;
}

 /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

 onFilterApplied(columFilter: any): void {
  this.searchColumFilter = columFilter;
  this.loadData();

}
setDataSource(uomList:UomModel[]){
  this.dataSource=new MatTableDataSource(uomList);
  this.dataSource.sort=this.sort;
  console.log(this.sort)
}
@ViewChild(MatSort) sort!: MatSort;
@ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
ngAfterViewInit(): void {

  this.loadPreviousState();
  // this.loadData();
}
 /**
   * On delete menu item selected
   * Confirm the action and call the api to update
   */
 onDelete(uomInfo: UomModel): void {
  const uomId = uomInfo.id;
  const message = `Are you sure, you want to delete ` + (uomInfo.name + "?");
  const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: dialogData
  });

  dialogRef.afterClosed().subscribe(dialogResult => {
    console.log(dialogResult);
    if (dialogResult) {
      this.UomService.delete(uomInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + uomInfo.name + " has been deleted succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.loadData();
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }
  });
}

/**
 * loads the data based on the conditions
 */
loadData(): void {
  this.isLoading = true;
  let requestData = this.buildRequestData();
  let response = this.UomService.getUomList(requestData).subscribe(response => {
    if (response.status == 'SUCCESS') {
      this.setDataSource(response.data.uoms);
      // this.uomList = response.data.uom;
      this.pagingData.length = response.data.total_count;
      if (this.pagingData.length == 0) {
        let snackBarData: SnackBarData = {
          message: 'No data found. Please remove or change the filter if any.',
          title: 'No Data!!!',
          type: 'warn'
        }
        this.snackBarService.openSnackBar(snackBarData);
        this.selectedItem = undefined;
      } else {
        this.onRowSelected(this.uomList[0])
      }
    } else {
      let snackBarData: SnackBarData = {
        message: response.message,
        title: 'Failed!!!',
        type: 'error'
      }
      this.snackBarService.openSnackBar(snackBarData);
    }
    this.isLoading = false;
  });

}
loadPreviousState(): void {

  var previousState = this.stateManagerService.getState(this.constructor.name);

  if (previousState !== undefined && this.searchFilter != undefined) {

    if (previousState.state.search.filters.scope) {
      this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
      this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
    }

    if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
      this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
    }

    this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
    this.searchFilter.setSearchFilter(this.searchColumFilter,true);

  } else {
    this.setSortColumn('name','asc');
    this.loadData();
  }

}
setSortColumn(column: string, direction: SortDirection): void {
  this.columnSortData.length = 0;
  this.columnSortData.push({ column: column, order: direction});
  console.log(this.sort)
  this.sort.sort({
    id: column,
    start: direction,
    disableClear:false,
});
}

// sortData($event: any): void {
//   var sd=this.sort;
//   this.columnSortData.length = 0;
//   this.columnSortData.push({ column: sd.active, order: sd.direction });
//   this.loadData();
// }
 /**
 * On uomInfo selected
 * @param uomInfo 
 */
 onRowSelected(uomInfo: UomModel): void {
  this.selectedItem = uomInfo;
  this.showSummaryPanel = true;
}

 /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
 sortData(sd: any): void {
  this.columnSortData.length = 0;
  this.columnSortData.push({ column: sd.active, order: sd.direction });
  this.loadData();
}
  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
getSearchParam(): SearchParam {

  return this.searchColumFilter;
}

  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(uomInfo: UomModel): void {

  this.router.navigate(['uom-edit', uomInfo.id]);
  }

  
  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }

  /**
   * On Add clicked
   * Show the dialog to enter new area
   */
  addClicked(): void {

    this.router.navigate(['uom-edit', 0]);

  }

  exportClicked() {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    this.IsDownloading = true;
    this.UomService.downloadExcel(requestData)
      .subscribe({
        next: blob => {
          // Download logic
          this.IsDownloading = false;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'uom_list.csv'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;


        },
        error: error => {
          // Handle errors
          this.IsDownloading = false;
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.isLoading = false;
          // Display user-friendly error message to the user
        }
      }
      );
  }

}









