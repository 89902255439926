
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { employeeModel } from './model/employee.model';
import { EmployeeService } from './employee.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DepartmentService } from 'src/app/pages/system/department/department.service';
import { DepartmentModel } from 'src/app/pages/system/shopinfo/model/shopinfo.model';
import { EmployeeCategoryModel} from './../employee-category/employee-category/model/employee-category.model';
import { EmployeeCategoryService } from './../employee-category/employee-category/employee-category.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrl: './employee.component.scss'
})
export class EmployeeComponent {
  employeeList: employeeModel[]=[];
  departmentList: DepartmentModel[] =[];
  employeeCatList: EmployeeCategoryModel[]=[];

  showSearch: boolean = false;
  isLoading = true;
  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "employees.code",
    },
    {
      title: "Name",
      column: "employees.f_name",
    },
    
    {
      title: "departments",
      column: "employees.department_id",
      type: "list",
      values: [],
      
    },
    {
      title: "catogery",
      column: "employees.employee_category_id",
      type: "list",
      values: [],
      
    },
    {
      title: "Status",
      column: "employees.status",
      values: [
        { title: "Active", value: "1" },
        { title: "Resigned", value: "2" },
      ]
    },
    
    
  ];
  displayedColumns = ['code', 'f_name','employee_category_id','department_id','status','action'];
  pagingData = { length: 0, pageSize: 20, pageIndex: 0 };
  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: employeeModel;
  showSummaryPanel: boolean = false;
  selection = new SelectionModel<employeeModel>(true, []);
  dataSource = new MatTableDataSource(this.employeeList);
  IsDownloading: boolean=false;

 constructor(private breakpointObserver: BreakpointObserver,
  private EmployeeService: EmployeeService,
  public dialog: MatDialog,
  private snackBarService: SnackBarService,
  private router: Router,  private stateManagerService: StateManagerService,
  private DepartmentService: DepartmentService,
  private EmployeeCategoryService: EmployeeCategoryService,
) {

  this.employeeList = [];
  this.departmentList = [];
  this.employeeCatList = [];
}
 /**
   * initilaize the device model
   */
 ngOnInit(): void {
  this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
    if (state.matches) {
      this.isHandset = true;
    } else {
      this.isHandset = false;
    }
  });
  this.isLoading = false; // Set to true on page load
  //this.loadData();
  this.loadDepartmentData();
  this.loadCatData();
}
 /** 
  * Set the datasource
  */
 setDataSource(employeeList:employeeModel[]){
  this.dataSource=new MatTableDataSource(employeeList);
  this.dataSource.sort=this.sort;
}
@ViewChild(MatSort) sort!: MatSort;
@ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
ngAfterViewInit(): void {

  this.loadPreviousState();
  // this.loadData();
}

/**
 * loads the previous state if exists
 */
loadPreviousState(): void {

  var previousState = this.stateManagerService.getState(this.constructor.name);

  if (previousState !== undefined && this.searchFilter != undefined) {

    if (previousState.state.search.filters.scope) {
      this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
      this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
    }

    if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
      this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
    }

    this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
    this.searchFilter.setSearchFilter(this.searchColumFilter,true);

  } else {
    this.setSortColumn('f_name','asc');
    this.loadData();
  }

}
  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction});
    console.log(this.sort)
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
    

  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.employeeList.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.employeeList);
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }

/**
   * Builds the request data to be send to API
   * @returns Request Data
   */
buildRequestData(): RequestData {
  let searchParam = this.getSearchParam();
  let requestData: RequestData = {
    search: {
      filters: {
        simpleSearch: searchParam.freeSearch,
        advSearch: searchParam.advSearch,
        scope: {
          limit: this.pagingData.pageSize,
          offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
        }
      },
      sort: this.getSort(),
      // sort: [
      //   { column: "f_name", order: "asc" },
        
      // ]
    }
  };

  return requestData;
}

 /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

 onFilterApplied(columFilter: any): void {
  this.searchColumFilter = columFilter;
  this.loadData();

}

 /**
   * On delete menu item selected
   * Confirm the action and call the api to update
   */
 onDelete(employeeInfo: employeeModel): void {
  const employeeId = employeeInfo.id;
  const message = `Are you sure, you want to delete ` + (employeeInfo.f_name + "?");
  const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: dialogData
  });

  dialogRef.afterClosed().subscribe(dialogResult => {
    console.log(dialogResult);
    if (dialogResult) {
      this.EmployeeService.delete(employeeInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + employeeInfo.f_name + " has been deleted succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.loadData();
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }
  });
}

/**
 * loads the data based on the conditions
 */
loadData(): void {
  this.isLoading = true;
  let requestData = this.buildRequestData();
  let response = this.EmployeeService.getemployeeList(requestData).subscribe(response => {
    if (response.status == 'SUCCESS') {
      this.setDataSource(response.data.employees);
      this.pagingData.length = response.data.total_count;
      if (this.pagingData.length == 0) {
        let snackBarData: SnackBarData = {
          message: 'No data found. Please remove or change the filter if any.',
          title: 'No Data!!!',
          type: 'warn'
        }
        this.snackBarService.openSnackBar(snackBarData);
        this.selectedItem = undefined;
      } else {
        this.onRowSelected(this.employeeList[0])
        this.stateManagerService.setState(this.constructor.name, requestData);
      }
    } else {
      let snackBarData: SnackBarData = {
        message: response.message,
        title: 'Failed!!!',
        type: 'error'
      }
      this.snackBarService.openSnackBar(snackBarData);
    }
    this.isLoading = false;
  });

}

 /**
 * On areainfo selected
 * @param employeeInfo 
 */
 onRowSelected(employeeInfo: employeeModel): void {
  this.selectedItem = employeeInfo;
  this.showSummaryPanel = true;
}

 /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
 sortData($event: any): void {
  var sd=this.sort;
  this.columnSortData.length = 0;
  this.columnSortData.push({ column: sd.active, order: sd.direction });
  this.loadData();
}
  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
getSearchParam(): SearchParam {

  return this.searchColumFilter;
}

  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(employeeInfo: employeeModel): void {

    this.router.navigate(['employee-edit', employeeInfo.id]);
  }

  
  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }

  /**
   * On Add clicked
   * Show the dialog to enter new area
   */
  addClicked(): void {

    this.router.navigate(['employee-edit', 0]);

  }

  exportClicked() {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    this.IsDownloading = true;
    this.EmployeeService.downloadExcel(requestData)
      .subscribe({
        next: blob => {
          // Download logic
          this.IsDownloading = false;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'region_list.csv'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;


        },
        error: error => {
          // Handle errors
          this.IsDownloading = false;
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.isLoading = false;
          // Display user-friendly error message to the user
        }
      }
      );
  }

  loadDepartmentData(): void {
    let requestData = {
      "search": {
          "filters": {
              "scope": {
                  "limit": 10,
                  "offset": 0
              }
          },
          "sort": [
              {
                  "column": "name",
                  "order": "asc"
              }
          ]
      }
  };
    let response = this.DepartmentService.getDepartmentList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.departmentList=response.data.department;
        this.filter[2].values = this.departmentList.map((cat: any) => {
          return { title: cat.name, value: cat.id };
        });
        // console.log(response.data.department)
  
       
       
      } 
    });
  
  }
  loadCatData(): void {
    let requestData = {
      "search": {
          "filters": {
              "scope": {
                  "limit": 10,
                  "offset": 0
              }
          },
          "sort": [
              {
                  "column": "name",
                  "order": "asc"
              }
          ]
      }
  };
    let response = this.EmployeeCategoryService.getEmployeeList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        //this.categoryList = response.data.categories;
        this.employeeCatList=response.data.employees ;
        this.filter[3].values = this.employeeCatList.map((cat: any) => {
          return { title: cat.name, value: cat.id };
        });
      
      } 
    });
  
  }
}
