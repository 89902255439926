<div class="report-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <mat-list-item class="mat-list-item" routerLink="/regions" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title"> Master Sales


                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/department" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Employee' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">Daily Sales
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/payroll" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Payroll Group' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">Weekly Sales

                        </span>
                    </div>
                </mat-list-item>
                <!-- <mat-list-item class="mat-list-item" routerLink="/shop" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Shop Payroll' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">Expense Type Summary
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/staff" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Staff Payroll' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">Yearly Result By Week

                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">Attendance Exceptions

                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">Cash Variance Exception

                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title"> Weekly Result By Store


                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">YTD Result By Store


                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reports.svg">
                        <span class="master-title">Weekly Result By Branch

                        </span>
                    </div>
                </mat-list-item> -->
           



            </div>
        </div>
    </div>
</div>