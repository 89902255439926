import { Injectable } from '@angular/core';
import { RequestData } from '../../../common/models/request-data.model';
import { StockCategoryModel } from './stock-category/model/stock-category.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class StockCategoryService {


  constructor(private http: HttpClient) { }

  
  getList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/category/get', requestData);

  }
 
  getParentCategory(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/category/getParentCategory', requestData);

  }

  getById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/category/get/'+id, '');

  }

   /**
   * 
   * @param areaInfo 
   * Calls the update API to insert/update the record
   */
   update(stockCategoryInfo: StockCategoryModel): Observable<any> { 
    if(stockCategoryInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/hq/category/update', stockCategoryInfo);
    } else{
      return this.http.post(environment.baseUrl+'/hq/category/add', stockCategoryInfo);
    }
  }


    /**
   * 
   * @param areaInfo 
   * Calls the API to delete the record
   */
    delete(stockCategoryInfo: StockCategoryModel): Observable<any>  {
      return this.http.post(environment.baseUrl+'/hq/category/delete/'+stockCategoryInfo.id, stockCategoryInfo);
    }
    downloadExcel(requestData: any): Observable<Blob> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL
  
      return this.http.post(environment.baseUrl + '/hq/category/export-stock-category', requestData, { headers, responseType: 'blob' })
        .pipe(
          //catchError(this.handleError)
        );
  
    }
}





