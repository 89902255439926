import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppConstants } from '../../common/app.constants';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { 

  }
  /** 
   * Login function
   */

  authenticate(data: any): Observable<any> {
    return this.http.post(environment.baseUrl+'/auth', data);
  }

  isloggedin():boolean{ 
    return sessionStorage.getItem(AppConstants.SESSION_VAR_USER)!=null;
  }

/** 
   * User Login function
   */

userLogin(data: any): Observable<any> {
  return this.http.post(environment.baseUrl+'/login/get', data);
}

/**
   *Get shop list 
   * @returns 
   */
   getShopList() {
    return this.http.get(environment.baseUrl + '/login/get-shop-list');
  }


}
