
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { vouchersModel } from '../model/vouchers.model';
import { VouchersService } from '../vouchers.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';

@Component({
  selector: 'app-vochers-edit',
  templateUrl: './vochers-edit.component.html',
  styleUrl: './vochers-edit.component.scss'
})
export class VochersEditComponent {
  title: string = "New voucher";
  editForm: FormGroup;
  voucherInfo: vouchersModel = {};
  isFormDirty: boolean = false;
  ratingList : vouchersModel[]= [];
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private VouchersService: VouchersService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let voucherId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.voucherInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.voucherInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.voucherInfo.description),
      value: this.builder.control(this.voucherInfo.value),
      is_overridable: this.builder.control(this.voucherInfo.is_overridable || false),
      is_change_payable: this.builder.control(this.voucherInfo.is_change_payable || false),
      is_valid: this.builder.control(this.voucherInfo.is_valid || false)
      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (voucherId !== null && parseInt(voucherId) !== 0) {
      this.setAreaInfo(voucherId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating();    
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/vouchers']);
    
  }

  /**
   * If tas is is null set an empty area info
   * if area id is not null call api to rtrive the info
   * @param voucherId 
   */
  setAreaInfo(voucherId: any): void {

    let response = this.VouchersService.getVoucherById(voucherId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.voucherInfo = response.data.vouchers;
          this.editForm.patchValue(this.voucherInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.voucherInfo.id == 0) ? "New voucher" : "Editing : " + this.voucherInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.voucherInfo, this.editForm.value);
      this.VouchersService.update(this.voucherInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.voucherInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/']);

          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }
}
