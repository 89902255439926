<div class="system-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <mat-list-item class="mat-list-item" routerLink="/area" [routerLinkActive]="['is-active']" (click)="resetState()"  [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/region.svg">
                        <span class="master-title" style="word-wrap: break-word;">Regions

                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/department" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Employee' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" >
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/department.svg">
                        <span class="master-title">Department</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/shops" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Payroll Group' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/shop.svg">
                        <span class="master-title"> Shop Info
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/settings" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Shop Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/settings.svg">
                        <span class="master-title">Settings</span>
                    </div>
                </mat-list-item>
                <!-- <mat-list-item class="mat-list-item" routerLink="/staff" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Staff Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/expenses.svg">
                        <span class="master-title">Expense
                        </span>
                    </div>
                </mat-list-item> -->
                <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reasons.svg">
                        <span class="master-title">Reasons
                        </span>
                    </div>
                </mat-list-item>
                <!-- <mat-list-item class="mat-list-item" routerLink="/attendence" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/shop.svg">
                        <span class="master-title">Shop Shift
                        </span>
                    </div>
                </mat-list-item> -->
         
           
         
         
            </div>
        </div>
    </div>
</div>
