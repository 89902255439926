import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { FormDeactivateGuard } from './common/guards/form-deactivate.guard';
import { EmployeeComponent } from './pages/employees/employee/employee.component';
import { SystemComponent } from './pages/system/system/system.component';
import { ProductComponent } from './pages/products/product/product.component';
import { PromotionComponent } from './pages/promotions/promotion/promotion.component';
import { ReportComponent } from './pages/reports/report/report.component';
import { UserComponent } from './pages/users/user/user.component';
import { DepartmentEditComponent } from './pages/system/department/department-edit/department-edit.component';
import { DepartmentComponent } from './pages/system/department/department.component';
import { EmployeeCategoryEditComponent } from './pages/employees/employee-category/employee-category/employee-category-edit/employee-category-edit.component';
import { EmployeeCategoryComponent } from './pages/employees/employee-category/employee-category/employee-category.component';
import { AreaComponent } from './pages/system/area/area.component';
import { AreaEditComponent } from './pages/system/area/area-edit/area-edit/area-edit.component';
import { ShopinfoComponent } from './pages/system/shopinfo/shopinfo.component';
import { ShopEditComponent } from './pages/system/shopinfo/shop-edit/shop-edit.component';
import { SystemSettingsComponent } from './pages/system/system-settings/system-settings.component';
import { UomComponent } from './pages/products/uom/uom.component';
import { ItemCategoryEditComponent } from './pages/products/item-category/item-category-edit/item-category-edit.component';
import { ItemCategoryComponent } from './pages/products/item-category/item-category.component';
import { SaleItemEditComponent } from './pages/products/sale-item/sale-item-edit/sale-item-edit.component';
import { SaleItemComponent } from './pages/products/sale-item/sale-item.component';
import { StockCategoryEditComponent } from './pages/products/stock-category/stock-category/stock-category-edit/stock-category-edit.component';
import { StockCategoryComponent } from './pages/products/stock-category/stock-category/stock-category.component';
import { StocksEditComponent } from './pages/products/stocks/stocks/stocks-edit/stocks-edit.component';
import { StocksComponent } from './pages/products/stocks/stocks/stocks.component';
import { UomEditComponent } from './pages/products/uom/uom-edit/uom-edit.component';
import { ReasonsComponent } from './pages/system/reasons/reasons.component';
import { ReasonsEditComponent } from './pages/system/reasons/reasons-edit/reasons-edit.component';
import { VouchersComponent } from './pages/promotions/vouchers/vouchers.component';
import { VochersEditComponent } from './pages/promotions/vouchers/vochers-edit/vochers-edit.component';
import { EmployeeEditComponent } from './pages/employees/employee/employee-edit/employee-edit.component';

const routes: Routes = [
  { component: EmployeeComponent, path: 'employee'},
  {component:EmployeeEditComponent,path:'employee-edit/:id'},
  { component: SystemComponent, path: 'system'},
  { component: ProductComponent, path: 'product'},
  { component: PromotionComponent, path: 'promotion'},
  { component: ReportComponent, path: 'report'},
  { component: UserComponent, path: 'user'},
  { component: DepartmentEditComponent, path: 'department-edit/:id'},
  { component:DepartmentComponent,path: 'department'},
  { component: EmployeeCategoryEditComponent, path: 'employee-category-edit/:id'},
  {component:EmployeeCategoryComponent,path: 'employee-category'},
  {component:AreaComponent,path:'area'},
  {component:AreaEditComponent,path:'area-edit/:id'},
  {component:ShopEditComponent,path:'shops'},
  // { component: ShopEditComponent, path: 'shop-edit/:id'},
  {component:SystemSettingsComponent,path:'settings'},
  { component: UomComponent, path: 'uom'},
  { component: UomEditComponent, path: 'uom-edit/:id'},

  { component: StocksComponent, path: 'stocks',  },
  { component: StocksEditComponent, path: 'stocks-edit/:id'},
  { component: StockCategoryEditComponent, path: 'stock-category-edit/:id'},
  { component: ShopinfoComponent, path: 'shops',  },
  { component: ShopEditComponent, path: 'shop-edit/:id'},
  { component: StockCategoryComponent, path: 'stock-category'},
  { component: ItemCategoryComponent, path: 'item-category'},
  { component: ItemCategoryEditComponent, path: 'item-category-edit/:id'},
  { component: SystemSettingsComponent, path: 'system-settings',  },
  { component: SaleItemComponent, path: 'sale-items',  },
  { component: SaleItemEditComponent, path: 'sale-item-edit/:id'},
  { component: ReasonsComponent, path: 'attendence'},
  {component:ReasonsEditComponent,path:'attendence-edit/:id'},
  {component:VouchersComponent,path:'vouchers'},
  {component:VochersEditComponent,path:'vouchers-edit/:id'},
  











];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [FormDeactivateGuard]
})
export class AppRoutingModule { }
