import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ToolbarComponent } from './common/components/toolbar/toolbar.component';
import { SidenavComponent } from './common/components/sidenav/sidenav.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";

import { LoginComponent } from './pages/login/login.component';
import { UserInfoComponent } from './common/components/user-info/user-info.component';
// import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { ConfirmDialogComponent } from './common/components/confirm-dialog/confirm-dialog.component';
import { MasterFloatingMenuComponent } from './common/components/master-floating-menu/master-floating-menu.component';
// import { UserListItemComponent } from './pages/user/components/user-list-item/user-list-item.component';
// import { UserComponent } from './pages/user/user.component';
import { SnackBarComponent } from './common/components/snackbar/snackbar.component';
// import { UserEditDialogComponent } from './pages/user/dialogs/user-edit-dialog/user-edit-dialog.component';
import { StylePaginatorDirective } from './common/components/paginator/style-paginator.directive';
import { SimplePaginatorComponent } from './common/components/paginator/simple-paginator/simple-paginator.component';
import { SorterComponent } from './common/components/sorter/sorter.component';
import { LoaderComponent } from './common/components/loader/loader.component';
import { SearchFilterComponent } from './common/components/search-filter/search-filter.component';
import { FilterItemComponent } from './common/components/search-filter/filter-item/filter-item.component';

// import { UserSummaryComponent } from './pages/user/components/user-summary/user-summary.component';
import { RewardSubmenuComponent } from './common/components/sidenav/submenu/reward-submenu/reward-submenu.component';
import { ExtraPagesMenuComponent } from './common/components/extra-pages-menu/extra-pages-menu.component';
import { SearchFilterSaveDialogComponent } from './common/components/search-filter/dialogs/search-filter-save-dialog/search-filter-save-dialog.component';
import { SearchFilterLoadDialogComponent } from './common/components/search-filter/dialogs/search-filter-load-dialog/search-filter-load-dialog.component';
import { FilterDialogComponent } from './common/components/search-filter/dialogs/filter-dialog/filter-dialog.component';
// import { ChangePasswordDialogComponent } from './pages/user/dialogs/change-password-dialog/change-password-dialog.component';
import { OrderStatusPipe } from './common/pipes/order-status.pipe';
import { ReversePipe } from './common/pipes/reverse.pipe';
import { DaysCustomPipe } from './common/pipes/days.pipe';
import { MonthsCustomPipe } from './common/pipes/month.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OODatePipe } from './common/pipes/date.pipe';
import { QuillModule } from 'ngx-quill';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
import { EmployeeMenuComponent } from './pages/employees/employee-menu/employee-menu.component';
import { EmployeeComponent } from './pages/employees/employee/employee.component';
import { SystemComponent } from './pages/system/system/system.component';
import { SystemMenuComponent } from './pages/system/system-menu/system-menu.component';
import { ProductComponent } from './pages/products/product/product.component';
import { ProductMenuComponent } from './pages/products/product-menu/product-menu.component';
import { PromotionMenuComponent } from './pages/promotions/promotion-menu/promotion-menu.component';
import { PromotionComponent } from './pages/promotions/promotion/promotion.component';
import { ReportComponent } from './pages/reports/report/report.component';
import { UserMenuComponent } from './pages/users/user-menu/user-menu.component';
import { UserComponent } from './pages/users/user/user.component';
import { ReportMenuComponent } from './pages/reports/report-menu/report-menu.component';
import { DepartmentComponent } from './pages/system/department/department.component';
import { DepartmentEditComponent } from './pages/system/department/department-edit/department-edit.component';
import { DepartmentSummaryComponent } from './pages/system/department/components/department-summary/department-summary.component';
import { EmployeeCategoryComponent } from './pages/employees/employee-category/employee-category/employee-category.component';
import { EmployeeCategorySummaryComponent } from './pages/employees/employee-category/employee-category/components/employee-category-summary/employee-category-summary.component';
import { EmployeeCategoryEditComponent } from './pages/employees/employee-category/employee-category/employee-category-edit/employee-category-edit.component';
import { AreaComponent } from './pages/system/area/area.component';
import { AreaSummaryComponent } from './pages/system/area/components/area-summary/area-summary.component';
import { AreaEditComponent } from './pages/system/area/area-edit/area-edit/area-edit.component';
import { ShopinfoComponent } from './pages/system/shopinfo/shopinfo.component';
import { ShopSummaryComponent } from './pages/system/shopinfo/components/shop-summary/shop-summary.component';
import { ShopEditComponent } from './pages/system/shopinfo/shop-edit/shop-edit.component';
import { SystemSettingsComponent } from './pages/system/system-settings/system-settings.component';
import { UomComponent } from './pages/products/uom/uom.component';
import { UomEditComponent } from './pages/products/uom/uom-edit/uom-edit.component';
import { UomSummaryComponent } from './pages/products/uom/components/uom-summary/uom-summary.component';
import { ItemCategoryComponent } from './pages/products/item-category/item-category.component';
import { ItemCategoryEditComponent } from './pages/products/item-category/item-category-edit/item-category-edit.component';
import { ItemCategorySummaryComponent } from './pages/products/item-category/components/item-category-summary/item-category-summary.component';
import { StockCategoryComponent } from './pages/products/stock-category/stock-category/stock-category.component';
import { StocksSummaryComponent } from './pages/products/stocks/stocks/components/stocks-summary/stocks-summary.component';
import { StockCategoryEditComponent } from './pages/products/stock-category/stock-category/stock-category-edit/stock-category-edit.component';
import { StocksComponent } from './pages/products/stocks/stocks/stocks.component';
import { StocksEditComponent } from './pages/products/stocks/stocks/stocks-edit/stocks-edit.component';
import { SaleItemComponent } from './pages/products/sale-item/sale-item.component';
import { SaleItemEditComponent } from './pages/products/sale-item/sale-item-edit/sale-item-edit.component';
import { SaleItemSummaryComponent } from './pages/products/sale-item/components/sale-item-summary/sale-item-summary.component';
import { ReasonsComponent } from './pages/system/reasons/reasons.component';
import { ReasonsEditComponent } from './pages/system/reasons/reasons-edit/reasons-edit.component';
import { VouchersComponent } from './pages/promotions/vouchers/vouchers.component';
import { VochersEditComponent } from './pages/promotions/vouchers/vochers-edit/vochers-edit.component';
import { EmployeeEditComponent } from './pages/employees/employee/employee-edit/employee-edit.component';
@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    SidenavComponent,
    LoginComponent,
    UserInfoComponent,
    ConfirmDialogComponent,
    MasterFloatingMenuComponent,
    // UserComponent,
    // UserListItemComponent,
    SnackBarComponent,
    // UserEditDialogComponent,
    StylePaginatorDirective,
    SimplePaginatorComponent,
    SorterComponent,
    SearchFilterComponent,
    FilterItemComponent,
    LoaderComponent,
    // UserEditComponent,
    SearchFilterComponent,
    // UserSummaryComponent,
    RewardSubmenuComponent,
    ExtraPagesMenuComponent,
    // ChangePasswordDialogComponent,
    SearchFilterLoadDialogComponent,
    SearchFilterSaveDialogComponent,
    SearchFilterSaveDialogComponent,
    FilterDialogComponent,
    OrderStatusPipe,
    ReversePipe,
    DaysCustomPipe,
    MonthsCustomPipe,
    OODatePipe,
    ExtraPagesMenuComponent,
    EmployeeMenuComponent,
    EmployeeComponent,
    SystemComponent,
    ReasonsComponent,
    SystemMenuComponent,
    ProductComponent,
    ProductMenuComponent,
    PromotionMenuComponent,
    PromotionComponent,
    ReportComponent,
    UserMenuComponent,
    UserComponent,
    ReportMenuComponent,
    AreaComponent,
    AreaSummaryComponent,
    AreaEditComponent,
    DepartmentComponent,
    DepartmentEditComponent,
    DepartmentSummaryComponent,
    EmployeeCategoryComponent,
    EmployeeCategorySummaryComponent,
    EmployeeCategoryEditComponent,
    ShopinfoComponent,
    ShopSummaryComponent,
    ShopEditComponent,
    SystemSettingsComponent,
    UomComponent,
    UomEditComponent,
    UomSummaryComponent,
    ItemCategoryComponent,
    ItemCategoryEditComponent,
    ItemCategorySummaryComponent,
    StockCategoryComponent,
    StocksSummaryComponent,
    StockCategoryEditComponent,
    StocksComponent,
    StocksSummaryComponent,
    StocksEditComponent,
    SaleItemComponent,
    SaleItemEditComponent,
    SaleItemSummaryComponent,
    ReasonsEditComponent,
    VouchersComponent,
    VochersEditComponent,
    EmployeeEditComponent,

  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    QuillModule.forRoot({
      modules: {
        resize: {

          showSize: false,
          toolbar: {
            sizeTools: false,
            alingTools: true
          },
        },

      },
      theme: 'snow'
    })],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    DatePipe
  ]
})
export class AppModule { }